import styled from "styled-components";
import { FaSms } from 'react-icons/fa';
import { AiOutlineMail } from 'react-icons/ai';
import { BsFillTelephoneFill } from 'react-icons/bs';
import { RiMapPin2Fill } from 'react-icons/ri';
import logo from './assets/logo.svg'

function App() {
  const direction = 'https://www.google.com/maps/place/1211+W+McFadden+Ave,+Santa+Ana,+CA+92707/@33.7347945,-117.885764,17z/data=!3m1!4b1!4m5!3m4!1s0x80dcd8e35982a3fb:0x7faec7f13f53c7d0!8m2!3d33.7347901!4d-117.8835753';
  const phone = 'tel:7142305976';
  const sms = 'sms:7142305976';
  const email = 'mailto:miguelzuniga@rocketmail.com';
  return (
    <AppContainer>
      <img className="hero-image" src="https://images.unsplash.com/photo-1533000971552-6a962ff0b9f9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80" alt="" />
      <div className="hero flex-center-column">
        <img className="logo-img" src={logo} alt="" />
        {/* <h1 className="text-shadow">Centro Cristiano Bethel</h1> */}
        <p className="text-shadow">Nuestra reunion es todos los domingos a las 10Am</p>
        <div className="button-container flex">
          <button className="box-shadow"><a href={direction} target='_blank' rel="noreferrer"><RiMapPin2Fill className="icon" /></a></button>
          <button className="box-shadow"><a href={phone}><BsFillTelephoneFill className="icon" /></a></button>
          <button className="box-shadow"><a href={sms}><FaSms className="icon" /></a></button>
          <button className="box-shadow"><a href={email}><AiOutlineMail className="icon" /></a></button>
        </div>
      </div>
    </AppContainer>
  );
}

export default App;

const AppContainer = styled.div`
  position: relative;
  height: 100%;
  overflow: hidden;
  .hero-image{
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: -1;
    object-fit: cover;
  }
  .hero {
    min-height: 100vh;
    width: 70%;
    margin: 0 auto;
    gap: 2em;
    .logo-img {
      width: 30em;
      height: 20em;
    }
    h1 {
      font-size: 6rem;
      letter-spacing: 1px;
      color: white;
      line-height: 1em;
      text-align: center;
    }
    p {
      font-size: 1.5rem;
      color: white;
      letter-spacing: 1px;
      text-align: center;
      z-index: 1;
    }
    .button-container {
      gap: 1em;
      .icon {
        font-size: 2rem;
        color: white;
      }
      button {
        padding: 1em 1em;
        background-color: #346eb9b0;
        border: none;
        color: white;
        font-weight: 700;
        letter-spacing: 1px;
        &:hover {
          background-color: #00f7ff;
          color: grey;
        }
      }
    }
  }
`;